module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Foundation Day","short_name":"Foundation Day","start_url":"/","background_color":"#FFFFFF","theme_color":"#4faae9","display":"standalone","icon":"src/assets/images/favicon32x32.png","pngns":[{"src":"/favicon16x16.png","sizes":"16x16","type":"image/png"},{"src":"/favicon32x32.png","sizes":"32x32","type":"image/png"},{"src":"/favicon64x64.png","sizes":"64x64","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"5f31c9ff6ab0fe0df1d51a16c1dcaa15"},
    },{
      plugin: require('../node_modules/gatsby-plugin-piwik-pro/gatsby-browser.js'),
      options: {"plugins":[],"containerUrl":"https://test-2.containers.piwik.pro","siteId":"e8c0f8be-0aa8-4b5b-9052-32175309ae00","enabled":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
